<template>
  <v-dialog v-model="dialog" fullscreen scrollable persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-circle-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="#365d84" flat dark style="max-height: 64px">
        <v-toolbar-title>Формируемые компетенции</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :disabled="saving" @click="dialog = false" light>
          <v-icon>mdi-close</v-icon> Закрыть
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="!loading">
        <v-row>
          <v-col>
            <v-card elevation="12" style="min-height: 100%; height: 100%">
              <v-toolbar color="#365d84" flat dark>
                <v-toolbar-title>Формируемые компетенции</v-toolbar-title>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text v-if="!loading" style="height: 100%">

                <draggable
                  v-bind="dragOptions"
                  @end="dragEnd"
                  draggable=".item"
                  style="min-height: 30px; height: 100%"
                  group="a"
                  v-model="programmCompet"
                >
                                <div  slot="header" class="text-center pa-3" style="border:dashed 1px" v-show="Object.values(programmCompet).length==0">
                  <div class="text-h6">
                    Перетащите формируемые компетенции в это окно
                  </div>
                <img src="/data/competence/select.gif" style="width:540px;height:330px" alt="[video-to-gif output image]">
                </div>
                  <v-sheet
                    class="ma-1 pa-1 item"
                    style="
                      border: 1px solid;
                      border-radius: 3px;
                      cursor: pointer;
                      background: #fafafa;
                    "
                    v-for="(compet,index) in programmCompet"
                    :key="compet.id"
                  >
                    <v-row>
                      <v-col cols="auto" class="pr-0 py-1">
                        <v-chip class="mx-1">{{ compet.type }} {{ compet.code }}</v-chip>
                      </v-col>
                      <v-col class="pl-2 py-1">{{ compet.name }}</v-col>
                      <v-col cols="auto" class="text-right">
                        <v-chip v-if="!compet.eduProgId" color="primary"
                          >ИЗ ФГОС</v-chip
                        >
                        <div v-else>
                        <competencyCreate :competence="compet"  :eduProgId="eduProgId"
                                 @update="$set(programmCompet,index,$event)"/>
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="grey"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="pa-2"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                          <span
                            >Если Вы хотите окончательно удалить эту
                            компетенцию, переместите её в список справа</span
                          >
                        </v-tooltip>
                      </div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="12" style="height: 100%">
              <v-toolbar color="#365d84" flat dark>
                <v-toolbar-title>Доступные компетенции</v-toolbar-title>
                <v-spacer></v-spacer>
                <competencyCreate
                  :eduProgId="eduProgId"
                  @create="fgosCompetList.push($event)"
                />
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text v-if="!loading" style="height: 100%">
                <draggable
                  v-bind="dragOptions"
                  @end="dragEnd"
                  style="min-height: 30px; height: 100%"
                  v-model="fgosCompetList"
                  group="a"
                >
                  <v-sheet
                    class="ma-1 pa-1"
                    style="
                      border: 1px solid;
                      border-radius: 3px;
                      cursor: pointer;
                      background: #fafafa;
                    "
                    v-for="(compet,index) in fgosCompetList"
                    :key="compet.id"
                  >
                    <v-row>
                      <v-col cols="auto" class="pr-0 py-1">
                        <v-chip class="mx-1">{{ compet.type }} {{ compet.code }}</v-chip>
                      </v-col>
                      <v-col class="pl-2 py-1">{{ compet.name }}</v-col>
                      <v-col cols="auto" class="text-right">
                        <v-chip v-if="!compet.eduProgId" color="primary"
                          >ИЗ ФГОС</v-chip
                        >
                        <div v-else><competencyCreate :competence="compet"  :eduProgId="eduProgId"
                                 @update="$set(fgosCompetList,index,$event)"/>
                          <deleteBtn
                       
                          title="Удалить"
                          :text="
                            'Вы действительно хотите окончательно удалить компетенцию: ' +
                            compet.name
                          "
                          :actionHref="
                            'competencies/delete?competenceId=' +
                            compet.id +
                            '&eduProgId=' +
                            eduProgId
                          "
                          @remove="
                            fgosCompetList.splice(
                              fgosCompetList.findIndex(
                                (el) => el.id === compet.id
                              ),
                              1
                            )
                          "
                      />
                    </div>
                        </v-col>
                    </v-row>
                  </v-sheet>
                </draggable>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row>
          <v-col>
            <v-skeleton-loader
              class="mx-auto"
              type="card-heading, list-item-avatar-two-line@5"
            ></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader
              class="mx-auto"
              type="card-heading, list-item-avatar-two-line@5"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style >
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
<script>
import deleteBtn from "../../delete.vue";
import competencyCreate from "./competencyCreate";
import draggable from "vuedraggable";
export default {
  props: ["skillId", "eduProgId"],
  data() {
    return {
      tab: 0,
      loading: false,
      dialog: false,
      saving: false,
      programmCompet: [],
      fgosCompet: [],
      fgosCompetList: [],
      drag: false,
    };
  },
  components: {
    draggable,
    competencyCreate,
    deleteBtn,
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    dialog(val) {
      if (val === true) {
        this.fetchData();
      } else{
        this.$emit('close')
      }
    },
      programmCompet(newVal, oldVal) {
      if (!this.loading) {
        let newElements = newVal.filter(x => !oldVal.includes(x));
        if (newElements.length > 0) {
            this.$http.get(
              "competencies/list-add",{
                params: {
                      eduProgId: this.eduProgId,
                    competenceId:newElements[0].id
                  }
                }
            );
        }
        let removeElements = oldVal.filter(x => !newVal.includes(x));
        if (removeElements.length > 0) {
          this.deleteElement(this.eduProgId,removeElements[0])

        }
      }
    },
  },
  methods: {
         deleteElement(eduProgId,competence,force=0){
           this.$http.get(
            "competencies/list-remove", {
            params: {
              eduProgId: eduProgId,
              competenceId: competence.id,
              force:force
            }
          }
          ).catch(e => {
            if (e.response) {
              if (e.response.status == 449) {
                if (confirm(e.response.data)) {
                  this.deleteElement(eduProgId,competence,1)
                } else {
                  this.programmCompet.unshift(competence)
                  let index = this.fgosCompetList.findIndex(el=>el.id==competence.id)
                  this.fgosCompetList.splice(index,1)
                }
              }
              else {
                alert('Ошибка!')
              }
            }
          })
    },

    fetchData() {
      this.loading = true;
      this.$http
        .get("competencies/load-edit-data", {
          params: {
            eduProgId: this.eduProgId,
          },
        })
        .then((response) => {
          this.programmCompet = response.data.programmCompet;
          this.fgosCompet = response.data.fgosCompet;
          this.fgosCompetList = this.fgosCompet.filter(
            (el) =>
              this.programmCompet.findIndex((pEl) => el.id === pEl.id) === -1
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
    },
  },
  mounted() {
    //this.fetchData();
  },
};
</script>

<style>
</style>