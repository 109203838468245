<template>
  <v-card elevation="0" class="w-100 ma-0 rounded-0 h-100 d-flex flex-column" color="rgb(255 255 255 / 80%)">
    <v-toolbar color="rgb(90 126 161)"  flat dark>
      <v-toolbar-title>
          Формируемые компетенции
          <v-tooltip bottom max-width="500">
              <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" class="ml-3">mdi-information</v-icon>
              </template>
              Образовательные программы высшего образования в части профессиональных компетенций разрабатываются организациями, осуществляющими образовательную деятельность, на основе профессиональных стандартов (при наличии) и могут включать в себя компетенции, отнесенные к одной или нескольким специальностям и направлениям подготовки по соответствующим уровням профессионального образования или к укрупненным группам специальностей и направлений подготовки, а также к области (областям) и виду (видам) профессиональной деятельности, в том числе с учетом возможности одновременного получения обучающимися нескольких квалификаций. (часть 8.1. статьи 12 Федерального закона от 29.12.2012 г. № 273-ФЗ «Об образовании в Российской Федерации»).<br/><br/>
              В связи с тем, что ФГОС не разрабатываются в цифровом формате и имеют тенденцию к стихийным точечным изменениям в части компетенций, просим проверить актуальность УК (ОК) и ОПК в выбранных ФГОС.<br/><br/>
              В случае, если данные компетенции требуют обновления, Вы можете не перетаскивать их из поля, расположенного в правой части экрана, а добавить их самостоятельно, установив к ним соответствующий номер (№) и принадлежность к характеру компетенции (УК, ОК, ОПК и т.д.)<br/><br/>
              Вместе с тем обращаем внимание на то, что связь с профессиональными стандартами в первую очередь имеют ПК (профессиональные компетенции).<br/>
          </v-tooltip>
      </v-toolbar-title><v-spacer/>
      <div id="selectCompetencies" style="z-index:999999999999999999999">
        <edit :eduProgId="eduProgId" @close="fetchData()" :skillId="skillId" @updateCompetList="competencies = $event" />
      </div>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text v-if="!loading" class="h-100">
    <v-text-field class="ma-3" v-model="search" append-icon="mdi-magnify" label="Поиск" single-line
      hide-details></v-text-field>
      <v-list color="transparent">
        <template v-for="competence of competencies" >
        <v-list-item :key="competence.id">
          <v-list-item-action>
            <v-chip class="mx-1">{{ competence.type }} {{ competence.code }}</v-chip>
          </v-list-item-action>
          <v-list-item-content>
            {{ competence.name }}

          </v-list-item-content>
 
          <v-list-item-action>
            <v-chip v-if="!competence.eduProgId" color="primary" class="text-body-2">ИЗ ФГОС</v-chip>
            
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="'d'+competence.id"/>
      </template>
      </v-list>
    </v-card-text>
    <div v-else class="w-100 h-100 d-flex align-center justify-center">
        <v-progress-circular 
    
      :size="60"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>
      </div>
    <v-tour name="selectCompetenciesTour" :steps="selectCompetenciesSteps"
      :options="{ highlight: true, labels: { buttonStop: 'ОК' } }"></v-tour>
  </v-card>
</template>
<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .4);

}

.v-chip {
  position: unset;
}
</style>

<script>
import edit from "./edit";
export default {
  name: "StarterPage",
  props: ["eduProgId", 'skillId'],
  data: () => ({
    competencies: [],
    count: 0,
    loading: true,
    search: "",
    headers: [
      { text: "Код", value: "type" },
      { text: "Название", value: "name" },
      { text: "Доп", value: "alert" },
    ],
    selectCompetenciesSteps: [
      {
        target: '#selectCompetencies',  // We're using document.querySelector() under the hood
        header: {
          title: 'Компентенции',
        },
        content: `Добавьте формируемые компетенеции в образовательную программу`
      },],
    itemsPerPage: 1000,
    page: 1,
    searchString: ''
  }),
  components: {
    edit,
  },
  methods: {
    searchFilter(value, search, item) {
      return (
        item.name
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        item.type
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      );
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get(
          "competencies/list?eduProgId=" +
          this.eduProgId +
          "&page=" +
          this.page +
          "&perPage=" +
          this.itemsPerPage +
          "&string=" +
          this.searchString
        )
        .then((response) => {
          this.competencies = response.data.competencies;
          this.count = parseInt(response.data.count);
          if (this.count == 0) {
            this.$tours['selectCompetenciesTour'].start()
          }
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
