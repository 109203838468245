<template>

        <!-- <eduStand  :loading="loading" :eduProg.sync="eduProg"/> -->
        <competencies  :eduProgId="eduProg.id"  :skillId="eduProg.skillId" />

</template>

<script>
import competencies from './competencies/list.vue'
// import eduStand from './eduStand.vue'
export default {
    props:['eduProg','loading'],
    components:{
        // eduStand,
            competencies
    }
}
</script>

<style>

</style>