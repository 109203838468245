<template>
  <v-dialog v-model="dialog" scrollable width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="ml-3" dark v-bind="attrs" v-on="on" v-if="!form.id">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn color="primary" class="ml-3" icon dark v-bind="attrs" v-on="on" v-else>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="#365d84" flat dark style="max-height: 64px">
        <v-toolbar-title v-if="!form.id">Создать компетенцию</v-toolbar-title>
        <v-toolbar-title v-else>Изменить компетенцию</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :disabled="saving" @click="dialog = false" light>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-alert
          border="top"
          colored-border
          type="info"
          elevation="2"
          class="mt-3"
          v-if="!form.id"
        >
          Тут Вы можете создать компетенцию прикрепленную к этой образовательной
          ораганизации
        </v-alert>
        <v-select class="mt-3"
          :items="['ОК', 'ОПК', 'ПК', 'ПСК', 'УК']"
          v-model="form.type"
          label="Тип"
          :error="errors.type ? true : false"
          :error-messages="errors.type ? errors.type : []"
          :rules="rules.required"
        ></v-select>
        <v-text-field
          v-model="form.code"
          :counter="11"
          :error="errors.code ? true : false"
          :error-messages="errors.type ? errors.code : []"
          :rules="rules.required"
          label="Код"
          required
        ></v-text-field>
        <v-text-field
          v-model="form.name"
          :counter="11"
          :error="errors.name ? true : false"
          :error-messages="errors.name ? errors.name : []"
          :rules="rules.required"
          label="Компетенция"
          required
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-space-between"
        ><v-spacer />
        <v-btn color="green" dark :loading="saving" v-if="!form.id" @click="save()">Добавить</v-btn>
        <v-btn color="green" dark :loading="saving" v-else @click="update()">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["eduProgId",'competence'],
  data() {
    return {
      dialog: false,
      saving: false,
      errors: [],
      form: {},
      rules: {
        required: [(v) => !!v || "Не должно быть пустым"],
      },
    };
  },
  watch:{
    dialog(val){
      if(val && this.competence){
        this.form=JSON.parse(JSON.stringify(this.competence)) 
      }
    }
  },
  mounted(){
    if(this.competence){
      this.form=JSON.parse(JSON.stringify(this.competence)) 
    }
  },
  methods: {
    save() {
      this.saving = true;
      this.$http
        .post("competencies/create?eduProgId=" + this.eduProgId,this.form)
        .then((response) => {
          this.errors = [];
          this.form = {};
          this.$emit("create", response.data);
          this.dialog = false;
        })
        .catch((e) => {
            this.errors=e.response.data
        })
        .finally(() => {
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      this.$http
        .post("competencies/update?id=" + this.form.id,this.form)
        .then((response) => {
          this.errors = [];
          this.$emit("update", response.data);
          this.dialog = false;
        })
        .catch((e) => {
            this.errors=e.response.data
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style>
</style>